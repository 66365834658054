import React from 'react';
import { Routes , Route} from 'react-router-dom';

import Home from './pages/Home';
import Sobre from './pages/Sobre';
import Servicos from './pages/Servicos';
import Localizacao from './pages/Localizacao';
import NotFound from './pages/NotFound';

export default () => {
    return (
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/sobre" element={<Sobre/>} />
            <Route path="/localizacao" element={<Localizacao/>} />
            <Route path="/servicos" element={<Servicos/>} />
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    )
}
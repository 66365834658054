import React from "react";
import "./styles.css";

import GoogleMapReact from 'google-map-react';

type Props = {
    text: any;
    lat: number;
    lng: number;
  }; 
 
const AnyReactComponent = ({ text }: Props) => <div>{text}</div>;

const Maps = () => {
  const defaultProps = {
    center: {
      lat: -23.53786, 
      lng:  -46.47795
    },
    zoom: 18
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '45vh', width: '100%' }}>
     
     {/**
      <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent
          lat={-23.53789}
          lng={-46.477832}
          text="Fabicar"
        />
      </GoogleMapReact> 
      */}

      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1828.9149812270932!2d-46.478429494553964!
                3d-23.538617394754443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce6729de227a6d%3A0xd495f5d197b0a8a5!2s
                Fabicar%20Eletronica%20Automotiva!5e0!3m2!1spt-BR!2sbr!4v1711159705465!5m2!1spt-BR!2sbr"
                    width="90%" height="300" style={{border: '0'}}  loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                </iframe>
    </div>
  );
}

export default Maps;
import { useRef } from "react";
import { useInView } from "framer-motion";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import "./styles.css";

type Props = {
  children: any;
};

const Section = ({ children }: Props) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section ref={ref}>
      <span
        style={{
          transform: isInView ? "none" : "translateX(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }}
      >
        {children}
      </span>
    </section>
  );
}
   
const TextAnimado = () => {
  return (
    <div className="div_text_animado">
 
      <Section>
          <div className="d-flex">
            <img className="rounded-circle m-auto" style={{ backgroundColor: '#fdce54', padding: '10px' }} 
              src="/img/text_anim_logo.png" 
              alt="Generic placeholder image" width="70" height="70" />
          </div> 
         <h5 className="text_center">  Missão  </h5> 
         <p></p>
         <h5 className="text_left px-5"> Ser o parceiro de nossos clientes.</h5> 
         <h5 className="text_left px-5"> Buscar contínuamente a excelência em cuidar e reparar de seu bem material.</h5>
         <h5 className="text_left px-5"> Garantir a confiabilidade de nossos serviços.</h5>
      </Section>

      <Section>
          <div className="d-flex">
            <img className="rounded-circle m-auto" style={{ backgroundColor: '#fdce54', padding: '10px' }} 
              src="/img/text_anim_logo2.png" 
              alt="Generic placeholder image" width="70" height="70" />
          </div> 
         <h5 className="text_center">  Visão  </h5> 
         <p></p>
         <h5 className="text_left"> Servir de referencia no setor automotivo de elétrica e eletrônica.</h5> 
         <h5 className="text_left"> Encontrar as soluções que atendam satisfatóriamente a todos os clientes.</h5>
         <h5 className="text_left"> Ser uma Empresa que influencia positivamente a vida de todos</h5> 
      </Section>

      <Section>
          <div className="d-flex">
            <img className="rounded-circle m-auto" style={{ backgroundColor: '#fdce54', padding: '10px' }} 
              src="/img/text_anim_logo3.png" 
              alt="Generic placeholder image" width="70" height="70" />
          </div> 
         <h5 className="text_center">  Valores  </h5> 
         <p></p>
         <h5 className="text_left"> Confiabilidade</h5> 
         <h5 className="text_left">Credibilidade</h5>
         <h5 className="text_left">Ética</h5>
         <h5 className="text_left"> Qualidade </h5> 
         <h5 className="text_left"> Resposabilidade sócio-ambiental</h5>
         <h5 className="text_left"> Transparência.</h5>
      </Section>
 
    </div>
  );
}

export default TextAnimado;
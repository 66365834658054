import React, { useEffect } from "react";
import "./styles.css";
import {
  Container,
  Row,
  Col,
  Carousel,
  Image,
  Card,
  Button,
  CardGroup,
} from "react-bootstrap";
import { FaAngleDown } from "react-icons/fa";

import TextAnimado from '../../components/text_animate';

import Whatsapp from '../../components/whatsapp';
 
const Localizacao = () => {

  const scrool = () => {
    window.location.href='#foo';
  }

  useEffect(()=>{
    scrool()
  },[])

  return (
    <>
      <Whatsapp />
      <div className="area">
        <div className="banner_area">
          <div className="banner">
           
                {/* BANNER */}
                <Carousel>

                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/banner_1.jpg"
                      alt="Equipamentos de ponta"
                    />

                    <Carousel.Caption>
                      {/* <h3>Equipamentos de ponta</h3> */}
                    </Carousel.Caption>
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/banner_2.jpg"
                      alt="Second slide"
                    />

                    <Carousel.Caption>
                      {/* <h3>Equipamentos de ponta</h3> */}
                    </Carousel.Caption>
                  </Carousel.Item>

                
                </Carousel>
              

              {/* BANNER FINAL*/}
            
          </div>
        </div>

        <hr className="featurette-divider"></hr>

        <a href="#" id="foo"></a>

        <div className="body_area">
          <div className="body_top">
            <div className="card mb-3" style={{ width: "100%", border: 'none', borderRight: '1px solid #DDDDDD' }}>
              <div className="row g-0">
                <div className="col-md-4">
                  <img
                    src="/img/localizacao.gif"
                    style={{ maxHeight: "330px" }}
                    className="img-fluid rounded-start"
                    alt="..."
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h5 className="card-title">Localização</h5>

                    <p className="text_left" style={{textAlign: 'justify' , textJustify: 'inter-word'}}>
                    Encontrar a localização ideal para uma oficina é crucial para o sucesso do negócio. 
                    A escolha do local certo pode impactar diretamente na visibilidade, acessibilidade e 
                    conveniência para os clientes.
                    
                    </p>
                    <p className="text_left" style={{textAlign: 'justify' , textJustify: 'inter-word'}}>
                    Uma oficina estrategicamente localizada em uma área de grande circulação de veículos 
                    ou próximo a regiões residenciais. 
                    
                    </p>

                    <p className="text_left" style={{textAlign: 'justify' , textJustify: 'inter-word'}}>
                    Além disso, a proximidade de fornecedores e serviços essenciais, 
                    como lojas de autopeças, pode otimizar o funcionamento interno da oficina.
                    </p>
                    <p className="card-text">
                      <small className="text-muted">
                        fabicar.com.br
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr className="featurette-divider pb-5"></hr>

        </div>
      </div>
    </>
  );
};

export default Localizacao; 

import React from 'react';

const NotFound = () => {
    return(
        <div style={{ 
            height: '60vh', display: 'flex', width: '100%' , justifyContent: 'center'
           }}>
            <div>
                <h1 style={{ height: 'auto', marginTop: '20%'}}>Ops! </h1>
                <h3>Esta página não foi encontrada.</h3>
             </div>   
            
        </div>
    )
}

export default NotFound;
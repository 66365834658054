import React from 'react';
import { Link } from "react-router-dom";
import "./styles.css";

const Whatsapp = () => {
    return(
        <div> 
            <Link to="https://wa.me/5511947509091?text=Vim+atrav%C3%A9s+do+site">
                <img src='/img/whatsapp.png' className='area_whatsapp' /> 
            </Link>
        </div>
    )
}

export default Whatsapp;
import React, { useEffect } from "react";
import "./styles.css";
import {
  Container,
  Row,
  Col,
  Carousel,
  Image,
  Card,
  Button,
  CardGroup,
} from "react-bootstrap";
import { FaAngleDown } from "react-icons/fa";

import TextAnimado from '../../components/text_animate';

import Whatsapp from '../../components/whatsapp';
 
const Servico = () => {
  const scrool = () => {
    window.location.href='#foo';
  }

  useEffect(()=>{
    scrool()
  },[])
  
  return (
    <>
      <Whatsapp />
      <div className="area">
        <div className="banner_area">
          <div className="banner">
           
                {/* BANNER */}
                <Carousel>

                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/banner_1.jpg"
                      alt="Equipamentos de ponta"
                    />

                    <Carousel.Caption>
                      {/* <h3>Equipamentos de ponta</h3> */}
                    </Carousel.Caption>
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/img/banner_2.jpg"
                      alt="Second slide"
                    />

                    <Carousel.Caption>
                      {/* <h3>Equipamentos de ponta</h3> */}
                    </Carousel.Caption>
                  </Carousel.Item>

                
                </Carousel>
              

              {/* BANNER FINAL*/}
            
          </div>
        </div>

        {/*Serviços 
        <div className="area_serv">

          <div className="area_serv_titulo">
            <h3 className="shadow_texte">Nossos Serviços</h3>
            <h5 className="mt-4 shadow_texte">Conserto Manuteção e Programação de Módulos Automotivos</h5>
          </div>

          <div className="area_serv_body">

            <div className="container overflow-hidden">
              <div className="row gy-5">
                <div className="col-4 back_text_serv">
                  <div className="p-3 border bg-light"><img src="/img/air_bag.png" className="icon" /> AirBags</div>
                </div>
                <div className="col-4 back_text_serv">
                  <div className="p-3 border bg-light"><img src="/img/abs.png" className="icon" /> Freios ABS</div>
                </div>
                <div className="col-4 back_text_serv">
                  <div className="p-3 border bg-light"><img src="/img/bsi.png" className="icon" /> Modulo Injeção Eletrônica </div>
                </div>
                <div className="col-4 back_text_serv">
                  <div className="p-3 border bg-light"><img src="/img/bsi.png" className="icon" /> Modulo Motor e Câmbio</div>
                </div>
                <div className="col-4 back_text_serv">
                  <div className="p-3 border bg-light"><img src="/img/direcao.png" className="icon" /> Direção Elétrica</div>
                </div>
                <div className="col-4 back_text_serv">
                  <div className="p-3 border bg-light"><img src="/img/bsi.png" className="icon" /> BSI / BSM </div>
                </div>
                <div className="col-4 back_text_serv">
                  <div className="p-3 border bg-light"><img src="/img/imobilizador.png" className="icon" /> Imobilizador</div>
                </div>
              </div>
            </div>

          </div>

          <div className="area_serv_titulo">
            <h5 className="shadow_texte">Trabalhamos com diversos tipos de diagnósticos.</h5>
          </div>

        </div>
        
        */}

        <hr className="featurette-divider"></hr>

        <a href="#" id="foo"></a>

        <div className="container-fluid" style={{ backgroundColor: '#efefef' }}>

        <div className="row featurette mt-4 mb-4 m-auto" style={{ alignSelf: 'end'}}>
          <div className="col-sm-3 col-md-6 col-lg-8" style={{ backgroundColor: '#efefef'  }}>
            <div className="overflow-hidden">
              <div className="row gy-4">
                <div>
                <h5 className="shadow_texte_2 mt-2">Nossos Serviços</h5>
                <h5 className="shadow_texte mt-2">Especialista em reparo, manutenção e programação módulos eletrônicos de carros  nacionais e importados.</h5>
                <h5 className="shadow_texte">Trabalhamos com diversos diagnósticos automotivos.</h5>
                </div>
                <div className="col-6 back_text_serv">
                  <div className="p-1 border bg-light"><img src="/img/injecao.png" className="icon" />Modulo Injeção Eletrônica </div>
                </div>
                <div className="col-6 back_text_serv">
                  <div className="p-1 border bg-light"><img src="/img/bsi.png" className="icon" />Modulo Motor e Câmbio</div>
                </div>
                <div className="col-6 back_text_serv">
                  <div className="p-1 border bg-light"><img src="/img/air_bag.png" className="icon" />AirBags</div>
                </div>
                <div className="col-6 back_text_serv">
                  <div className="p-1 border bg-light"><img src="/img/abs.png" className="icon" />Freios ABS</div>
                </div>
                <div className="col-6 back_text_serv">
                  <div className="p-1 border bg-light"><img src="/img/direcao.png" className="icon" />Direção Elétrica</div>
                </div>
                <div className="col-6 back_text_serv">
                  <div className="p-1 border bg-light"><img src="/img/bsi.png" className="icon" />BSI / BSM </div>
                </div>
                <div className="col-6 back_text_serv">
                  <div className="p-1 border bg-light"><img src="/img/imobilizador2.png" className="icon" />Imobilizador </div>
                </div>
                <div className="col-6 back_text_serv">
                  <div className="p-1 border bg-light"><img src="/img/bateria.png" className="icon" />Elétrica </div>
                </div>
                
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div style={{ justifyContent: 'right'}}>
              <img src="/img/banner_serv_3.jpg" style={{ maxHeight: '400px' }}/>
            </div>
            
          </div>
        </div>

        </div>
 
        <hr className="featurette-divider"></hr>

        {/*<!-- imagem redonda com texto -->*/}

        {/*
        <div className="container marketing">

          
          <div className="row pt-4 m-auto">
            <div className="col-lg-4">
              <img className="rounded-circle" src="data:image/gif;base64,R0lGODlhAQABAIAAAHd3dwAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Generic placeholder image" width="140" height="140" />
              <h2>Título</h2>
              <p>Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. Nullam id dolor id nibh ultricies vehicula ut id elit. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna.</p>
              <p><a className="btn btn-secondary" href="#" role="button">View details »</a></p>
            </div> 
            <div className="col-lg-4">
              <img className="rounded-circle" src="data:image/gif;base64,R0lGODlhAQABAIAAAHd3dwAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Generic placeholder image" width="140" height="140" />
              <h2>Título</h2>
              <p>Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Cras mattis consectetur purus sit amet fermentum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh.</p>
              <p><a className="btn btn-secondary" href="#" role="button">View details »</a></p>
            </div> 
            <div className="col-lg-4">
              <img className="rounded-circle" src="data:image/gif;base64,R0lGODlhAQABAIAAAHd3dwAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Generic placeholder image" width="140" height="140" />
              <h2>Título</h2>
              <p>Donec sed odio dui. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Vestibulum id ligula porta felis euismod semper. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.</p>
              <p><a className="btn btn-secondary" href="#" role="button">View details »</a></p>
            </div>
          </div>
        </div>

        */}

        <div className="body_area">

          <div className="body_bottom">

            <div className="row row-cols-1 row-cols-md-3 g-4 mb-4">
              <div className="col">
                <div className="card " >
                  <div className="div_titulo_banner_pequeno">
                    <h6 className="text_banner_pequeno">Diagnósticos Precisos</h6>
                  </div>
                  <img
                    src="/img/diag_precisos.png"
                    className="card-img-top"
                    alt="..."
                    style={{ backgroundColor: '#efefef' }}
                  />
                  <div className="card-body">
                    <h5 className="card-title"></h5>
                    <p className="card-text" style={{textAlign: 'justify' , textJustify: 'inter-word'}}>
                      O diagnóstico veicular é uma etapa fundamental para manter a saúde e o bom
                      funcionamento de um automóvel. Por meio de equipamentos específicos e da
                      expertise técnica de profissionais qualificados, é possível identificar
                      com precisão possíveis problemas e falhas nos sistemas do veículo.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card ">
                  <div className="div_titulo_banner_pequeno">
                    <h6 className="text_banner_pequeno">Equipamentos de Ponta</h6>
                  </div>
                  <img
                    src="/img/equip_ponta.png"
                    className="card-img-top"
                    alt="..."
                    style={{ backgroundColor: '#efefef' }}
                  />
                  <div className="card-body">
                    <h5 className="card-title"></h5>
                    <p className="card-text" style={{textAlign: 'justify' , textJustify: 'inter-word'}}>
                      Quando falamos em equipamento de ponta, estamos nos referindo a tecnologias
                      de última geração que representam o que há de mais avançado em suas respectivas
                      áreas de atuação. Estes equipamentos são caracterizados por sua alta precisão,
                      eficiência e capacidade.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card ">
                  <div className="div_titulo_banner_pequeno">
                    <h6 className="text_banner_pequeno">Mão de Obra Especializada</h6>
                  </div>
                  <img
                    src="/img/mao_esp.png"
                    className="card-img-top"
                    alt="..."
                    style={{ backgroundColor: '#efefef' }}
                  />
                  <div className="card-body">
                    <h5 className="card-title"></h5>
                    <p className="card-text" style={{textAlign: 'justify' , textJustify: 'inter-word'}}>
                      A mão de obra especializada desempenha um papel crucial no funcionamento e
                      sucesso de qualquer negócio. Profissionais com conhecimento técnico e
                      habilidades específicas são essenciais para garantir a eficiência,
                      qualidade e inovação em todas as etapas de um empreendimento.
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Servico; 

import React from 'react';
import { Link } from 'react-router-dom';
import { FooterArea } from './styled';
import { FaAngleDown } from "react-icons/fa";
import { FaPhoneSquare } from "react-icons/fa";

import Maps from '../../../components/maps';

 
const Footer = () => {
   
    return(
    <FooterArea>
        <div className='area'> 
            <div className='area_div' >         
                   <div className='div_footer_logo'>
                    <Link to="/">
                        <img src='/img/logo_footer.png' className='logoImg' />
                    </Link>
                    </div> 
                    {/*<p><FaAngleDown /> ABS &nbsp; <FaAngleDown /> Injeção  <FaAngleDown />  Air Bag  </p>*/}
                
                <h5 className='texto_color mt-4'>Endereço</h5>
                <p><span>Av. Aguia de Haia, 689 - Parque Paineiras</span> <span>Cep 03694-000 - São Paulo / SP</span></p>
                Próximo ao metrô Artur Alvim
            </div>
            <div className='area_div2'>
            <h5 className='texto_color'>Horário de Funcionamento</h5>
                De segunda a Sexta das 09:00 as 18:00hs <br/>
                <p><strong>Fechado:</strong> Sábado Domingo e Feriados </p>
                Trabalhamos com agendamento
                <h5 className='texto_color mt-4'>Contato</h5>
                <FaPhoneSquare />11 2045-2929 / 2855-0145 <br/>
                <p> <img src='/img/whatsapp.png' style={{ width: '20px' }}/> 11 94750-9091  </p>

            
                    <p> 
                        <a href='https://www.instagram.com/_fabicar/' style={{ textDecoration: 'none', color: '#FFF' }}> 
                            <img src='/img/insta_icon.png' style={{ width: '20px' }}/>  
                        </a>
                    </p>
 
            </div>
            <div className='area_div3'>
            <h5 className='texto_color'>Localização</h5>
                <div className='img_mapa'> <Maps /> </div> 
            </div>
        </div>
        </FooterArea>
       
    )
}

export default Footer;